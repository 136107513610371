import { lazy } from "react"
import { s } from "components/lib"

const MainPage = lazy(() => import("views/admin"))
const Leads = lazy(() => import("views/admin/leads"))
const Portfolios = lazy(() => import("views/admin/portfolios"))
const Metrics = lazy(() => import("views/admin/metrics"))
const Settings = lazy(() => import("views/admin/settings"))

const Routes = [
  {
    path: "/admin/dashboard",
    view: s(MainPage),
    layout: "admin",
    title: "Главная",
  },
  {
    path: "/admin/leads",
    view: s(Leads),
    layout: "admin",
    title: "Лиды",
  },
  {
    path: "/admin/portfolios",
    view: s(Portfolios),
    layout: "admin",
    title: "Портфолио",
  },
  {
    path: "/admin/metrics",
    view: s(Metrics),
    layout: "admin",
    title: "Метрики",
  },
  {
    path: "/admin/settings",
    view: s(Settings),
    layout: "admin",
    title: "Настройки",
  },
]

export default Routes
