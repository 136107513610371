import { lazy } from "react"
import { s } from "components/lib"
const Signin = lazy(() => import("views/admin/auth/signin"))

const Routes = [
  {
    path: "/admin",
    view: s(Signin),
    layout: "auth",
    title: "Авторизация",
  },
]

export default Routes
