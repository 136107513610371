import { Icon } from "components/lib"
import React from "react"
import { useNavigate } from "react-router-dom"

export function AdminLayout(props) {
  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <header className="bg-white p-4 flex justify-between items-center w-full shadow-sm fixed top-0 z-10">
        <div className="flex items-center gap-2">
          <Icon image="settings" />
          <h1 className="text-xl font-semibold text-gray-800">
            Talk&Call HR Admin
          </h1>
        </div>

        <div className="flex items-center gap-2">
          <div className="px-4 py-1 bg-gray-200 rounded-full hover:bg-red-400 hover:text-white cursor-pointer">
            Выйти
          </div>
          <div className="bg-gray-200 p-2 rounded-full hover:bg-indigo-400 hover:text-white cursor-pointer">
            <Icon image="settings" />
          </div>
          <div className="bg-gray-200 p-2 rounded-full hover:bg-indigo-400 hover:text-white cursor-pointer">
            <Icon image="bell" />
          </div>
          <div className="cursor-pointer">
            <img
              src="/ava.jpg"
              alt="avatar"
              className="w-10 h-10 rounded-full object-center"
            />
          </div>
        </div>
      </header>

      <div className="flex flex-1 pt-20">
        {" "}
        {/* Add padding-top to make space for the fixed header */}
        {/* Sidebar */}
        <aside className="w-64 bg-white h-full fixed top-20 left-0 z-10">
          <div className="flex items-center gap-2 px-4 mt-6">
            <div className="bg-slate-200 p-4 rounded-full">
              <Icon image="settings" />
            </div>
            <div className="flex flex-col">
              <span className="font-bold">Talk&Call HR</span>
              <span className="font-extralight text-gray-400">Admin Panel</span>
            </div>
          </div>
          <nav className="mt-6 space-y-3 px-4">
            <NavItem icon="home" label="Дашборд" link="dashboard" />
            <NavItem icon="users" label="Лиды" link="leads" />
            <NavItem icon="briefcase" label="Портфолио" link="portfolios" />
            <NavItem icon="settings" label="Настройки" link="settings" />
          </nav>

          <div className="p-4 mt-auto">
            <button className="w-full px-4 py-2 bg-indigo-400 text-white rounded-full hover:bg-indigo-500 transition">
              Добавить лид
            </button>
          </div>
        </aside>
        {/* Page Content */}
        <main className="flex-1 ml-64 p-6 overflow-y-auto h-screen">
          {<props.children {...props.data} />}
        </main>
      </div>
    </div>
  )
}

function NavItem({ icon, label, link }) {
  const navigate = useNavigate()

  return (
    <button
      className="flex items-center gap-2 px-4 py-2 w-full hover:bg-gray-100 transition rounded-full text-black"
      onClick={() => navigate(`/admin/${link}`)}
    >
      <Icon image={icon} />
      <span className="text-lg">{label}</span>
    </button>
  )
}
