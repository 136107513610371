import React from "react"

export const RadioGroup = ({
  id,
  label,
  value,
  options,
  onChange,
  required,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {label && (
        <div className="flex items-center gap-2">
          <label className="text-md font-semibold text-[#2f34c0]">
            {label}
          </label>
          {required && <span className="text-red-500">*</span>}
        </div>
      )}
      <div className="flex gap-4">
        {options.map((option, index) => (
          <label key={index} className="flex items-center space-x-2">
            <input
              name={id}
              type="radio"
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
              className="form-radio h-4 w-4 text-indigo-600"
            />
            <span className="text-gray-700">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  )
}
